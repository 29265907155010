import { Injectable } from '@angular/core';

import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  set<T>(key: string, data: Partial<T>): Promise<void> {
    return Preferences.set({
      key,
      value: JSON.stringify(data),
    });
  }

  async get<T>(key: string): Promise<T> {
    return JSON.parse((await Preferences.get({ key }))?.value);
  }

  clear(): void {
    Preferences.clear();
  }
}
