import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AlertController } from '@ionic/angular';

import { Auth } from '@aws-amplify/auth';

import { from, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { UserModel } from '../../models/user.model';

import { ToastService } from '../utils/toast.service';
import { LoadingService } from '../utils/loading.service';
import { StorageService } from 'src/app/core/services/utils/storage.service';
import { AuthDataService } from '../data/auth-data.service';
import { UserDataService } from '../data/user-data.service';
import { HttpService } from './generic/http.service';

import { environment } from 'src/environments/environment';

import { StorageConstant } from 'src/app/core/constants/storage.constant';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private toastService: ToastService,
    private loadingService: LoadingService,
    private router: Router,
    private storageService: StorageService,
    private authDataService: AuthDataService,
    private userDataService: UserDataService,
    private httpService: HttpService,
    private alertController: AlertController
  ) {}

  getCurrentUserSession(): Observable<CognitoUserSession> {
    return from(Auth.currentSession());
  }

  async signUp(user: UserModel): Promise<void> {
    const loader: HTMLIonLoadingElement = await this.loadingService.presentLoader();

    try {
      await Auth.signUp({
        username: user.firstName,
        password: user.password,
        attributes: {
          email: user.email,
        },
      });

      this.authDataService.setFormReset(true);
      this.userDataService.setConfirmSignUpUser(user);
      this.router.navigateByUrl('auth/confirm-sign-up');
    } catch (error) {
      if (error.name === 'UsernameExistsException') {
        this.toastService.showToast('First name is taken. Please choose another one.', 'danger');
      } else if (error.name === 'InvalidParameterException') {
        if (String(error.message).includes('failed to satisfy constraint')) {
          this.toastService.showToast(
            'Password does not meet requirement. Please follow hint.',
            'danger'
          );
        }
      } else if (error.name === 'UserLambdaValidationException') {
        this.toastService.showToast(
          'First name is unacceptable. Please choose a different one.',
          'danger'
        );
      }
    } finally {
      this.loadingService.dismissLoader(loader);
    }
  }

  async confirmSignUp(user: UserModel): Promise<void> {
    const loader: HTMLIonLoadingElement = await this.loadingService.presentLoader();

    try {
      await Auth.confirmSignUp(user?.firstName, user?.verificationCode, {
        forceAliasCreation: true,
      });

      this.toastService.showToast('Code has been verified', 'success');
      this.router.navigateByUrl('auth/sign-in');
    } catch (error) {
      if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
        this.toastService.showToast('Code has been verified', 'success');
        this.router.navigateByUrl('auth/sign-in');
      } else if (error.name === 'CodeMismatchException') {
        this.toastService.showToast(error?.message, 'danger');
      } else {
        this.toastService.showToast(error?.message, 'danger');
      }
    } finally {
      this.loadingService.dismissLoader(loader);
    }
  }

  async resendSignUp(user: UserModel): Promise<void> {
    const loader: HTMLIonLoadingElement = await this.loadingService.presentLoader();

    try {
      await Auth.resendSignUp(user.firstName);
      this.toastService.showToast('Code resent successfully. Please check your email.', 'success');
    } catch (error) {
      this.toastService.showToast(error?.message, 'danger');
    } finally {
      this.loadingService.dismissLoader(loader);
    }
  }

  async signIn(user: UserModel, loader: HTMLIonLoadingElement): Promise<void> {
    try {
      await Auth.signIn(user.firstName, user.password);

      switch (
        user?.loginFrom //send back to exact `login from` page
      ) {
        case 'group-profile': {
          await this.setSignInUser(user);
          this.router.navigateByUrl(
            `group/sydney-badminton/${user?.grId}/${user?.grName.replaceAll(' ', '-')}`
          );
          break;
        }
        case 'event-details': {
          await this.setSignInUser(user);
          this.router.navigateByUrl(`event/event-details/${user?.grId}/${user?.eventId}`);
          break;
        }
        case '/group': {
          await this.setSignInUser(user);
          this.router.navigateByUrl(`group`);
          break;
        }
        case '/landing/start-group': {
          await this.setSignInUser(user);
          this.router.navigateByUrl(`group`);
          break;
        }
        default: {
          this.getUser().subscribe(async (res) => {
            //to check isTerms
            if (res?.isTerms) {
              await this.setSignInUser(user);
              this.router.navigateByUrl(`app`);
            } else {
              this.openUpdatedTermsOfServiceAlert(user);
            }
          });
          break;
        }
      }
    } catch (error) {
      if (error.name === 'UserNotConfirmedException') {
        this.toastService.showToast('User is not confirmed.', 'danger');
        this.authDataService.setUserConfirmation(false);
      } else if (error.name === 'NotAuthorizedException') {
        this.toastService.showToast(error?.message, 'danger');
      } else {
        this.toastService.showToast(error?.message, 'danger');
      }
    } finally {
      this.authDataService.setLoginFromPageDetails('');
      this.loadingService.dismissLoader(loader);
    }
  }

  async forgotPassword(user: UserModel): Promise<void> {
    const loader: HTMLIonLoadingElement = await this.loadingService.presentLoader();

    try {
      await Auth.forgotPassword(user.firstName);
      this.router.navigateByUrl('auth/forgot-password-submit');
    } catch (error) {
      this.toastService.showToast(JSON.stringify(error?.message), 'danger');
    } finally {
      this.loadingService.dismissLoader(loader);
    }
  }

  async forgotPasswordSubmit(user: UserModel): Promise<void> {
    const loader: HTMLIonLoadingElement = await this.loadingService.presentLoader();

    try {
      await Auth.forgotPasswordSubmit(user.firstName, user.verificationCode, user.password);
      this.userDataService.setForgotPasswordUser(user);
      this.toastService.showToast('Code has been verified', 'success');
      this.router.navigateByUrl('auth/sign-in');
    } catch (error) {
      this.toastService.showToast(JSON.stringify(error?.message), 'danger');
    } finally {
      this.loadingService.dismissLoader(loader);
    }
  }

  async signOut(): Promise<void> {
    Auth.signOut();
    this.userDataService.setUser(null);
    this.storageService.clear();
    await this.storageService.set<boolean>(StorageConstant.IS_USER_SIGNED_OUT, true); //set this after `clear()`
    this.router.navigateByUrl('auth/sign-in');
  }

  private getUser(): Observable<UserModel> {
    return this.httpService
      .get<UserModel>(`${environment.apiBaseUrl}/v1/user/`)
      .pipe(shareReplay());
  }

  private updateUser(): Observable<void> {
    const payload: UserModel = {
      isTerms: true,
    };

    return this.httpService
      .put<void>(`${environment.apiBaseUrl}/v1/user`, payload)
      .pipe(shareReplay());
  }

  private async openUpdatedTermsOfServiceAlert(user: UserModel): Promise<void> {
    const alert = await this.alertController.create({
      header: 'Updated Terms of Service',
      message: `We updated our <span class="text-decoration-underline" id="terms-of-service">Terms of Service</span>
       and <span class="text-decoration-underline" id="privacy-policy">Privacy Policy</span>. By clicking "Continue",
         you confirm that you have read and agree to our updated terms.`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Continue',
          handler: async (): Promise<void> => {
            await this.setSignInUser(user);
            this.updateUser().subscribe(); //API call
            this.router.navigateByUrl(`app`);
          },
        },
      ],
      mode: 'ios',
      cssClass: 'play-next',
      backdropDismiss: false,
    });

    await alert.present();

    alert.querySelector('#terms-of-service').addEventListener('click', () => {
      this.router.navigateByUrl('legal/app-terms');
      this.alertController.dismiss();
    });

    alert.querySelector('#privacy-policy').addEventListener('click', () => {
      this.router.navigateByUrl('legal/privacy');
      this.alertController.dismiss();
    });
  }

  private async setSignInUser(user: UserModel): Promise<void> {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

    this.storageService.set<UserModel>(StorageConstant.USER, {
      firstName: currentAuthenticatedUser?.username.toLocaleLowerCase(),
    });

    this.userDataService.setUser({
      ...user,
      firstName: currentAuthenticatedUser?.username.toLocaleLowerCase(),
    });

    this.userDataService.setConfirmSignUpUser(null);
    this.authDataService.setFormReset(true);
  }
}
