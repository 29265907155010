import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  isUltraLargeDeviceChanged$: Observable<boolean>;
  showSideMenuChanged$: Observable<boolean>;
  private isUltraLargeDeviceSubject$: BehaviorSubject<boolean>;
  private showSideMenuSubject$: BehaviorSubject<boolean>;

  constructor() {
    this.initialize();
  }

  setIsUltraLargeDevice(isUltraLargeDevice: boolean): void {
    this.isUltraLargeDeviceSubject$.next(isUltraLargeDevice);
  }

  setShowSideMenu(showSideMenu: boolean): void {
    this.showSideMenuSubject$.next(showSideMenu);
  }

  getShowSideMenu(): boolean {
    return this.showSideMenuSubject$.getValue();
  }

  private initialize(): void {
    this.isUltraLargeDeviceSubject$ = new BehaviorSubject<boolean>(false);
    this.isUltraLargeDeviceChanged$ = this.isUltraLargeDeviceSubject$.asObservable();

    this.showSideMenuSubject$ = new BehaviorSubject<boolean>(false);
    this.showSideMenuChanged$ = this.showSideMenuSubject$.asObservable();
  }
}
