import { NgModule } from '@angular/core';

import { InputMaskModule } from 'primeng/inputmask';
import { AccordionModule } from 'primeng/accordion';
import { PanelMenuModule } from 'primeng/panelmenu';
import { CarouselModule } from 'primeng/carousel';
import { MenubarModule } from 'primeng/menubar';

@NgModule({
  declarations: [],
  imports: [InputMaskModule, AccordionModule, PanelMenuModule, CarouselModule, MenubarModule],
  exports: [InputMaskModule, AccordionModule, PanelMenuModule, CarouselModule, MenubarModule],
})
export class PrimengModule {}
