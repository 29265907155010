import { Injectable, ErrorHandler } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MenuController } from '@ionic/angular';

import { ToastService } from 'src/app/core/services/utils/toast.service';
import { AuthService } from '../apis/auth.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService implements ErrorHandler {
  constructor(
    private toastService: ToastService,
    private authService: AuthService,
    private storageService: StorageService,
    private menuController: MenuController
  ) {}

  handleError(error: any): void {
    if (Error instanceof HttpErrorResponse) {
      console.log(error.status);
    } else {
      console.error(error);
    }

    if (error === 'No current user') {
      this.signOut();
    } else {
      this.toastService.showToast('Oops! Please refresh your browser.', 'danger');
    }
  }

  private async signOut(): Promise<void> {
    this.storageService.clear();
    await this.authService.signOut();
    this.menuController.close();
  }
}
