export const environment = {
  production: true,
  env: 'prod',
  domain: 'localhost',
  apiBaseUrl: 'https://l5qrkjtkef.execute-api.ap-southeast-2.amazonaws.com/Prod',
  queueTime: 5000, // in milliseconds
  landingVideoUrl:
    'https://queue-general.s3.ap-southeast-2.amazonaws.com/videos/background-video.mp4',
  appVersion: require('../../package.json').version,
  sentryDsn:
    'https://365834ccd5aa9f2f32dee6b7eb8598f9@o4507534869528576.ingest.us.sentry.io/4508008787869696',
};
