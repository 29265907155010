import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthDataService {
  userConfirmationChanged$: Observable<boolean>;
  formResetChanged$: Observable<boolean>;
  loginFromChanged$: Observable<string>;
  private userConfirmationSubject$: BehaviorSubject<boolean>;
  private formResetSubject$: BehaviorSubject<boolean>;
  private loginFromSubject$: BehaviorSubject<string>;

  constructor() {
    this.initialize();
  }

  setUserConfirmation(isConfirmedUser: boolean): void {
    this.userConfirmationSubject$.next(isConfirmedUser);
  }

  setFormReset(isFormReset: boolean): void {
    this.formResetSubject$.next(isFormReset);
  }

  setLoginFromPageDetails(loginFrom: string): void {
    this.loginFromSubject$.next(loginFrom);
  }

  getLoginFromPageDetails(): string {
    return this.loginFromSubject$.getValue();
  }

  private initialize(): void {
    this.userConfirmationSubject$ = new BehaviorSubject<boolean>(true);
    this.userConfirmationChanged$ = this.userConfirmationSubject$.asObservable();

    this.formResetSubject$ = new BehaviorSubject<boolean>(false);
    this.formResetChanged$ = this.formResetSubject$.asObservable();

    this.loginFromSubject$ = new BehaviorSubject<string>('');
    this.loginFromChanged$ = this.loginFromSubject$.asObservable();
  }
}
