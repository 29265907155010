import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { GroupModel } from '../../group/shared/models/group.model';

import { StorageConstant } from 'src/app/core/constants/storage.constant';

import { StorageService } from 'src/app/core/services/utils/storage.service';

@Injectable({
  providedIn: 'root',
})
export class GroupDataService {
  groupDetailsChanged$: Observable<GroupModel>;
  groupsChanged$: Observable<GroupModel[]>;

  private groupDetailsSubject$: BehaviorSubject<GroupModel>;
  private groupsSubject$: BehaviorSubject<GroupModel[]>;

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  setGroupDetails(group: GroupModel): void {
    this.storageService.set<GroupModel>(StorageConstant.GROUP, group);
    this.groupDetailsSubject$.next(group);
  }

  getGroupDetails(): GroupModel {
    return this.groupDetailsSubject$.getValue();
  }

  setGroups(groups: GroupModel[]): void {
    this.groupsSubject$.next(groups);
  }

  private initialize(): void {
    this.groupDetailsSubject$ = new BehaviorSubject<GroupModel>(null);
    this.groupDetailsChanged$ = this.groupDetailsSubject$.asObservable();

    this.groupsSubject$ = new BehaviorSubject<GroupModel[]>(null);
    this.groupsChanged$ = this.groupsSubject$.asObservable();
  }
}
