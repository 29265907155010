import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule } from '@ionic/angular';

import { AppRoutingModule } from '../app-routing.module';

import { HttpInterceptorProviders } from './interceptors';

import { ErrorService } from './services/utils/error.service';

import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';

@NgModule({
  declarations: [PageNotFoundComponent, UnauthorizedComponent],
  imports: [CommonModule, IonicModule, AppRoutingModule, HttpClientModule, IonicModule],
  exports: [],
  providers: [HttpInterceptorProviders, { provide: ErrorHandler, useClass: ErrorService }],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() core: CoreModule) {
    if (core) {
      throw new Error('You should import core module only in the root module');
    }
  }
}
