import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LoadingService {

    constructor(private loadingController: LoadingController,) { }

    async presentLoader(message: string = 'Please wait...'): Promise<HTMLIonLoadingElement> {

        const loader: HTMLIonLoadingElement = await this.loadingController.create({
            message,
        });

        loader.present();
        return loader;
    }

    dismissLoader(loader: HTMLIonLoadingElement): Promise<boolean> {
        if (loader) { return loader.dismiss(); }
    }
}
