import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

import { BehaviorSubject, Observable } from 'rxjs';

import { checkMobileDeviceSizeFunction } from '../../landing/shared/functions/check-mobile-device-size.function';

@Injectable({
  providedIn: 'root',
})
export class DeviceSizeDataService {
  deviceSizeChanged$: Observable<boolean>;

  private deviceSizeSubject: BehaviorSubject<boolean>;

  constructor(private platform: Platform) {
    this.initialize();
  }

  setDeviceSize(): void {
    this.deviceSizeSubject.next(checkMobileDeviceSizeFunction(this.platform)); //'true' for small devices
  }

  private initialize(): void {
    this.deviceSizeSubject = new BehaviorSubject<boolean>(null);
    this.deviceSizeChanged$ = this.deviceSizeSubject.asObservable();
  }
}
