import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserModel } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  userChanged$: Observable<UserModel>;
  confirmSignUpUserChanged$: Observable<UserModel>;
  forgotPasswordUserChanged$: Observable<UserModel>;
  refreshTheAppChanged$: Observable<boolean>;

  private userSubject$: BehaviorSubject<UserModel>;
  private confirmSignUpUserSubject$: BehaviorSubject<UserModel>;
  private forgotPasswordUserSubject$: BehaviorSubject<UserModel>;
  private refreshTheAppSubject$: BehaviorSubject<boolean>;

  constructor() {
    this.initialize();
  }

  setUser(user: UserModel): void {
    this.userSubject$.next(user);
  }

  getUser(): UserModel {
    return this.userSubject$.getValue();
  }

  setConfirmSignUpUser(user: UserModel): void {
    this.confirmSignUpUserSubject$.next(user);
  }

  setForgotPasswordUser(user: UserModel): void {
    this.forgotPasswordUserSubject$.next(user);
  }

  setRefreshTheApp(isRefreshed: boolean): void {
    this.refreshTheAppSubject$.next(isRefreshed);
  }

  private initialize(): void {
    this.userSubject$ = new BehaviorSubject<UserModel>(null);
    this.userChanged$ = this.userSubject$.asObservable();

    this.confirmSignUpUserSubject$ = new BehaviorSubject<UserModel>(null);
    this.confirmSignUpUserChanged$ = this.confirmSignUpUserSubject$.asObservable();

    this.forgotPasswordUserSubject$ = new BehaviorSubject<UserModel>(null);
    this.forgotPasswordUserChanged$ = this.forgotPasswordUserSubject$.asObservable();

    this.refreshTheAppSubject$ = new BehaviorSubject<boolean>(null);
    this.refreshTheAppChanged$ = this.refreshTheAppSubject$.asObservable();
  }
}
