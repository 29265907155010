import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastController: ToastController, private cookieService: CookieService) {}

  async showToast(
    message: string,
    color: string,
    position: any = 'bottom',
    duration: number = 5000
  ): Promise<HTMLIonToastElement> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      duration,
      position,
      color,
      buttons: [
        {
          text: 'OK',
          handler: (): any => {},
        },
      ],
    });

    toast.present();
    return toast;
  }

  async showCookieToast(): Promise<HTMLIonToastElement> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message:
        'We use different types of cookies to give you the best experience on our website. ' +
        'By continuing, you agree to our <a href="/legal/cookies"> Cookie Policy.</a>',
      duration: 0,
      position: 'bottom',
      color: 'tertiary',
      cssClass: 'cookie-toast',
      buttons: [
        {
          text: 'Continue',

          handler: (): any => {
            this.dismissToast(toast);
            this.cookieService.set('doubles-team-cookies', 'yes');
          },
        },
      ],
    });

    toast.present();
    return toast;
  }

  private dismissToast(toast: HTMLIonToastElement): void {
    toast?.dismiss();
  }
}
