import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(url, body);
  }

  get<T>(url: string, params?: any): Observable<T> {
    return this.httpClient.get<T>(url, { params });
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(url, body);
  }

  delete<T>(url: string, params: any): Observable<T> {
    return this.httpClient.delete<T>(`${url}/${params}`);
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.httpClient.patch<T>(url, body);
  }
}
