<ion-app>
  <ion-split-pane contentId="main-content" class="split-pane">
    <ion-menu
      contentId="main-content"
      type="overlay"
      *ngIf="(showSideMenuChanged$ | async) === false"
    >
      <ion-content>
        <ion-grid>
          <ion-row class="ion-text-center row1 ion-align-items-center">
            <ion-col>
              <img [src]="logo" class="height-40" button (click)="goToHome()" />
            </ion-col>
          </ion-row>

          <ion-row class="row2">
            <ion-col class="ion-margin-start">
              <p-panelMenu [model]="menuItems" [style]="{ width: '250px' }"></p-panelMenu>
            </ion-col>
          </ion-row>
        </ion-grid>

        <div class="app-version">
          <ion-label class="color-light-white">{{ appVersion }}</ion-label>
        </div>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>
