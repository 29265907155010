import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { UnauthorizedComponent } from './core/components/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    path: 'landing',
    loadChildren: (): any =>
      import('../app/modules/landing/landing.module').then((m) => m.LandingModule),
  },
  {
    path: 'auth',
    loadChildren: (): any => import('../app/modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'event',
    loadChildren: (): any => import('../app/modules/event/event.module').then((m) => m.EventModule),
  },
  {
    path: 'queue',
    loadChildren: (): any => import('../app/modules/queue/queue.module').then((m) => m.QueueModule),
  },
  {
    path: 'info',
    loadChildren: (): any => import('../app/modules/info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'group',
    loadChildren: (): any => import('../app/modules/group/group.module').then((m) => m.GroupModule),
  },
  {
    path: 'app',
    loadChildren: (): any => import('../app/modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'legal',
    loadChildren: (): any => import('../app/modules/legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'subscription',
    loadChildren: (): any =>
      import('../app/modules/subscription/subscription.module').then((m) => m.SubscriptionModule),
  },
  {
    path: '',
    redirectTo: '/landing',
    pathMatch: 'full',
  },
  {
    path: 'page-not-found',
    component: PageNotFoundComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '**',
    redirectTo: 'page-not-found',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
