import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

import Amplify, { Auth } from '@aws-amplify/auth';
import AwsConfig from '../aws-exports';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular-ivy';

import { AppComponent } from './app.component';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { PrimengModule } from './modules/shared/primeng.module';

import { CookieService } from 'ngx-cookie-service';

import { environment } from '../environments/environment';

Amplify.configure(AwsConfig);
Auth.configure(AwsConfig);

Sentry.init(
  {
    //dsn: environment.production ? environment.sentryDsn : '', //run only on prod mode
    dsn: environment.sentryDsn, //run only on local mode

    // Set your release version, such as "getsentry@1.0.0"
    release: `queue@${environment.appVersion}`,
    // Set your dist version, such as "1"
    dist: '1',
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    PrimengModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    CookieService,
    {
      provide: ErrorHandler,
      // Attach the Sentry ErrorHandler
      useValue: SentryAngular.createErrorHandler(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
