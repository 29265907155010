import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, Subscription } from 'rxjs';

import { MenuController, Platform } from '@ionic/angular';

import { CookieService } from 'ngx-cookie-service';

import { MenuItem } from 'primeng/api';

import * as LogRocket from 'logrocket';
import * as dayjs from 'dayjs';
import { register } from 'swiper/element/bundle';

import { StorageConstant } from 'src/app/core/constants/storage.constant';

import { UserModel } from 'src/app/core/models/user.model';
import { GroupModel } from './modules/group/shared/models/group.model';
import { EventModel } from './modules/event/shared/models/event.model';

import { AuthService } from 'src/app/core/services/apis/auth.service';
import { StorageService } from 'src/app/core/services/utils/storage.service';
import { UserDataService } from './core/services/data/user-data.service';
import { ScreenSizeService } from './core/services/utils/screen-size.service';
import { GroupDataService } from './modules/services/data/group-data.service';
import { DeviceSizeDataService } from './modules/services/data/device-size-data.service';
import { ToastService } from './core/services/utils/toast.service';
import { EventDataService } from './modules/services/data/event-data.service';

import { environment } from '../environments/environment';

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  groupDetailsChanged$: Observable<GroupModel>;
  showSideMenuChanged$: Observable<boolean>;
  menuItems: MenuItem[];
  logo = 'assets/images/svgs/logo.svg';
  appVersion = 'App version : ' + environment.appVersion;

  private userChangedSubscription: Subscription;
  private refreshTheAppSubscription: Subscription;
  private signOutInterval: any;

  constructor(
    private authService: AuthService,
    private storageService: StorageService,
    private userDataService: UserDataService,
    private router: Router,
    private platform: Platform,
    private screenSizeService: ScreenSizeService,
    private groupDataService: GroupDataService,
    private deviceSizeDataService: DeviceSizeDataService,
    private menuController: MenuController,
    private location: Location,
    private toastService: ToastService,
    private cookieService: CookieService,
    private eventDataService: EventDataService
  ) {}

  @HostListener('window:resize', ['$event'])
  setScreenSize(): void {
    const screenWidth = window.innerWidth;

    screenWidth > 1300
      ? this.screenSizeService.setIsUltraLargeDevice(true)
      : this.screenSizeService.setIsUltraLargeDevice(false);
  }

  ngOnInit(): void {
    this.initializeApp();

    this.userChangedSubscription = this.userDataService.userChanged$.subscribe((user) => {
      if (!user) {
        return;
      }

      if (environment.env === 'prod') {
        LogRocket.init('08oarl/queue-' + environment.env);
        LogRocket.identify(user?.firstName);
      }
    });
  }

  goToHome(): void {
    this.menuController.close();
    this.router.navigateByUrl('landing');
  }

  ngOnDestroy(): void {
    if (this.signOutInterval) {
      clearInterval(this.signOutInterval);
    }

    this.userChangedSubscription?.unsubscribe();
    this.refreshTheAppSubscription?.unsubscribe();
  }

  private async initializeApp(): Promise<void> {
    const signedInUser = await this.storageService.get<UserModel>(StorageConstant.USER);
    const group = await this.storageService.get<GroupModel>(StorageConstant.GROUP);
    this.groupDataService.setGroupDetails(group);

    const isAdmin = this.groupDataService.getGroupDetails()?.grIsAdmin;

    const event = await this.storageService.get<EventModel>(StorageConstant.EVENT);
    this.eventDataService.setEvent(event);

    const url = this.location.path();

    this.setSignOutWorkflow();

    if (signedInUser) {
      //auth use case
      // Browser refresh use case
      this.userDataService.setUser(signedInUser);

      if (url.includes('settings/admins') && !isAdmin) {
        this.router.navigateByUrl('unauthorized');
      } else if (url.includes('group/my')) {
        this.router.navigateByUrl('group/my'); //external `/group/my` link */
      } else if (url.includes('event/my')) {
        this.router.navigateByUrl('event/my'); //external `/event/my` link */
      } else if (url.includes('city-badminton/groups')) {
        this.router.navigateByUrl(`group/city-badminton/groups`);
      } else if (url.includes('city-badminton/events')) {
        this.router.navigateByUrl(`event/event/city-badminton/events`);
      } else if (url.includes('queue')) {
        //e.g. queue/9d5ec6/2023-09-05T09:55:00
        const splittedByQueue = url.split('queue/');
        const groupEventDetails = splittedByQueue?.[1].split('/');
        this.router.navigateByUrl(`queue/${groupEventDetails?.[0]}/${groupEventDetails?.[1]}`);
      } else if (url.includes('group/sydney-badminton')) {
        //e.g. group/sydney-badminton/1ahlyrvv/Baddie-Mates-7
        const splittedByGroupLocation = url.split('group/sydney-badminton/');
        const groupDetails = splittedByGroupLocation?.[1].split('/');
        this.router.navigateByUrl(
          `group/sydney-badminton/${groupDetails?.[0]}/${groupDetails?.[1]}`
        );
      } else if (url.includes('legal/terms')) {
        this.router.navigateByUrl(`legal/terms`);
      } else if (url.includes('legal/website-terms')) {
        this.router.navigateByUrl(`legal/website-terms`);
      } else if (url.includes('legal/app-terms')) {
        this.router.navigateByUrl(`legal/app-terms`);
      } else if (url.includes('legal/privacy')) {
        this.router.navigateByUrl(`legal/privacy`);
      } else if (url.includes('legal/cookies')) {
        this.router.navigateByUrl(`legal/cookies`);
      } else if (url.includes('info/help-admin')) {
        this.router.navigateByUrl(`info/help-admin`);
      } else if (url.includes('info/help')) {
        this.router.navigateByUrl(`info/help`);
      } else if (url.includes('subscription/subscribe')) {
        //e.g. subscription/subscribe?u=11vXFb2jRl6fjN%2BGaTH%2BTw%3D%3D
        const uId = url.split('=');
        this.router.navigateByUrl(`subscription/subscribe?u=${uId?.[1]}`);
      } else if (url.includes('subscription/unsubscribe')) {
        //e.g. subscription/unsubscribe?u=11vXFb2jRl6fjN%2BGaTH%2BTw%3D%3D
        const uId = url.split('=');
        this.router.navigateByUrl(`subscription/unsubscribe?u=${uId?.[1]}`);
      } else if (url.includes('event/event-details')) {
        this.router.navigateByUrl(url);
      } else if (url.includes('landing')) {
        const splittedLanding = url.split('landing');
        if (splittedLanding?.[1] === '/start-group') {
          this.router.navigateByUrl(`landing/start-group`);
        }
      } else {
        url === '/group'
          ? this.router.navigateByUrl('group') //external `/group` link */
          : this.router.navigateByUrl(`app`);
      }
    } else {
      //no signed in use cases: No auth
      if (url.includes('city-badminton/groups')) {
        this.router.navigateByUrl(`group/city-badminton/groups`);
      } else if (url.includes('city-badminton/events')) {
        this.router.navigateByUrl(`event/event/city-badminton/events`);
      } else if (url.includes('info/help')) {
        this.router.navigateByUrl(`info/help`);
      } else if (url.includes('legal/app-terms')) {
        this.router.navigateByUrl(`legal/app-terms`);
      } else if (url.includes('landing')) {
        const splittedLanding = url.split('landing');
        if (splittedLanding?.[1] === '/start-group') {
          this.router.navigateByUrl(`landing/start-group`);
        } else {
          this.router.navigateByUrl('landing');
        }
      }
    }

    this.disableHardwareBackButton();
    this.setMenu();
    this.deviceSizeDataService.setDeviceSize();
    this.refreshTheApp();
    this.setScreenSize();

    this.groupDetailsChanged$ = this.groupDataService.groupDetailsChanged$ ?? null;
    this.showSideMenuChanged$ = this.screenSizeService.showSideMenuChanged$;

    //check cookies
    const cookieExists = this.cookieService.check('doubles-team-cookies');
    if (!cookieExists) {
      this.toastService.showCookieToast();
    }
  }

  private disableHardwareBackButton(): void {
    this.platform.backButton.subscribeWithPriority(99999, () => {});
  }

  private refreshTheApp(): void {
    this.refreshTheAppSubscription = this.userDataService.refreshTheAppChanged$.subscribe((res) => {
      if (!res) {
        return;
      }

      this.userDataService.setRefreshTheApp(null);
      window.location.reload();
    });
  }

  private setMenu(): void {
    this.userChangedSubscription = this.userDataService.userChanged$.subscribe((user) => {
      this.menuItems = [
        {
          label: `${user?.firstName}`,
          icon: 'pi pi-user',
          visible: user ? true : false,
          styleClass: 'user-name',
          items: [
            {
              label: 'My Home',
              routerLink: 'app',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'My Events',
              routerLink: 'event/my',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'My Groups',
              routerLink: 'group/my',
              command: (): any => this.menuController.close(),
            },
          ],
        },

        {
          label: 'Log In',
          icon: 'pi pi-user',
          styleClass: !user ? 'log-in display-block' : 'display-none',
          routerLink: 'auth/sign-in',
          command: (): any => this.menuController.close(),
        },
        {
          label: 'Play',
          icon: 'pi pi-send',

          items: [
            {
              label: 'All Events',
              routerLink: 'event/event/city-badminton/events',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'All Groups',
              routerLink: 'group/city-badminton/groups',
              command: (): any => this.menuController.close(),
            },
          ],
        },
        {
          label: 'Help',
          icon: 'pi pi-question',
          items: [
            {
              label: 'User Help',
              routerLink: 'info/help',
              command: (): any => this.menuController.close(),
            },
          ],
        },
        {
          label: 'Settings',
          icon: 'pi pi-cog',
          items: [
            {
              label: 'Start a Group',
              routerLink: 'landing/start-group',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'Terms & Condition',
              routerLink: 'legal/app-terms',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'Privacy Policy',
              routerLink: 'legal/privacy',
              command: (): any => this.menuController.close(),
            },
            {
              label: 'Cookies Policy',
              routerLink: 'legal/cookies',
              command: (): any => this.menuController.close(),
            },
          ],
        },
        {
          label: 'Sign Out',
          icon: 'pi pi-power-off',
          command: (): any => this.signOut(),
          styleClass: user ? 'sign-out display-block' : 'display-none',
        },
      ];
    });
  }

  private async signOut(): Promise<void> {
    this.storageService.clear();
    await this.authService.signOut();
    this.menuController.close();
    this.userDataService.setRefreshTheApp(true);
  }

  setSignOutWorkflow(): void {
    this.signOutInterval = setInterval(() => {
      const hour = dayjs().hour(); // gets current hour
      if (hour >= 0 && hour <= 1) {
        // mid night
        this.signOut();
      }
    }, 3600 * 1000); //check on every 1 hour
  }
}
