import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { AuthService } from '../services/apis/auth.service';
import { UserDataService } from '../services/data/user-data.service';
import { ToastService } from '../services/utils/toast.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private userDataService: UserDataService,
    private router: Router,
    private toastService: ToastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<CognitoUserSession>> {
    let authReq: HttpRequest<any> = request;

    const user = this.userDataService.getUser();

    //no need token for venue
    if (authReq.url.indexOf('venue') >= 0 && authReq.method === 'GET') {
      return next.handle(authReq.clone());
    }

    const splittedByGroup = authReq.url.split('group/');
    if (
      splittedByGroup?.length === 2 &&
      splittedByGroup[1] !== '' &&
      splittedByGroup[1]?.indexOf('?') < 0 &&
      authReq.method === 'GET'
    ) {
      //no need token for group/[groupId] when no user
      if (!user) {
        return next.handle(authReq.clone());
      }
    }

    const splittedByEvent = authReq.url.split('event/');
    if (
      splittedByEvent?.length === 2 &&
      splittedByEvent[1] !== '' &&
      splittedByEvent[1]?.indexOf('/') > 0 &&
      authReq.method === 'GET'
    ) {
      //no need token for event/[groupId]/[eventId] when no user
      if (!user) {
        return next.handle(authReq.clone());
      }
    }

    if (authReq.url.indexOf('type=all') >= 0 || authReq.url.indexOf('type=group') >= 0) {
      //no need token for all groups
      return next.handle(authReq.clone());
    }

    const splittedBySubscription = authReq.url.split('subscription?');
    if (
      splittedBySubscription?.length === 2 &&
      splittedBySubscription[1] !== '' &&
      (authReq.method === 'GET' || authReq.method === 'PUT')
    ) {
      //no need token for subscription? when no user
      if (!user) {
        return next.handle(authReq.clone());
      }
    }

    return this.authService.getCurrentUserSession().pipe(
      take(1),
      switchMap((auth: CognitoUserSession) => {
        try {
          const jwt = auth.getIdToken().getJwtToken();

          if (request?.method === 'PATCH') {
            authReq = request.clone({
              setHeaders: {
                'content-type': 'application/json-patch+json',
                authorization: `Bearer ${jwt}`,
              },
            });
          } else {
            authReq = request.clone({
              setHeaders: {
                'content-type': 'application/json',
                authorization: `Bearer ${jwt}`,
              },
            });
          }

          return next.handle(authReq);
        } catch (error) {
          //handle NotAuthorizedException
          this.toastService.showToast('An error has occurred.', 'danger');
          this.router.navigateByUrl('auth/sign-in');
        }
      })
    );
  }
}
