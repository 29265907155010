<div>

  <div class="row1"></div>

  <ion-row class="row2">

    <ion-col size-xl="6" offset-xl="3.2" size="12">

      <ion-card>

        <ion-card-content>

          <div class="ion-text-center">

            <h1 class="font-size-64">404</h1>

            <h1>We can't find the page.</h1>

            <ion-row>

              <ion-col size="6" offset="3">

                <ion-button color="primary" class="ion-text-center ion-margin-top text-transform-none" routerLink="/">
                  Go to Home
                </ion-button>

              </ion-col>

            </ion-row>

          </div>

        </ion-card-content>

      </ion-card>

    </ion-col>

  </ion-row>

</div>