import { Injectable } from '@angular/core';

import { orderBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { StorageService } from 'src/app/core/services/utils/storage.service';

import { EventModel } from '../../event/shared/models/event.model';
import { StorageConstant } from 'src/app/core/constants/storage.constant';

@Injectable({
  providedIn: 'root',
})
export class EventDataService {
  eventsChanged$: Observable<EventModel[]>;
  eventChanged$: Observable<EventModel>;
  courtNumbersChanged$: Observable<string[]>;

  private eventsSubject$: BehaviorSubject<EventModel[]>;
  private eventSubject$: BehaviorSubject<EventModel>;
  private courtNumbersSubject$: BehaviorSubject<string[]>;

  constructor(private storageService: StorageService) {
    this.initialize();
  }

  setEvents(events: EventModel[]): void {
    this.eventsSubject$.next(orderBy(events, ['evId'], ['asc']));
  }

  setEvent(event: EventModel): void {
    this.storageService.set<EventModel>(StorageConstant.EVENT, event);
    this.eventSubject$.next(event);
  }

  getEvent(): EventModel {
    return this.eventSubject$.getValue();
  }

  setCourtNumbers(courtNumbers: string[]): void {
    this.courtNumbersSubject$.next(courtNumbers);
  }

  private initialize(): void {
    this.eventsSubject$ = new BehaviorSubject<EventModel[]>(null);
    this.eventsChanged$ = this.eventsSubject$.asObservable();

    this.eventSubject$ = new BehaviorSubject<EventModel>(null);
    this.eventChanged$ = this.eventSubject$.asObservable();

    this.courtNumbersSubject$ = new BehaviorSubject<string[]>([]);
    this.courtNumbersChanged$ = this.courtNumbersSubject$.asObservable();
  }
}
